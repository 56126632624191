import { format } from "date-fns"
import React from "react"
import { buildImageObj } from "./lib/helpers"
import { imageUrlFor } from "./lib/image-url"
import PortableText from "./portableText"
import Container from "./container"
import AuthorList from "./author-list"
import { Link } from "gatsby-plugin-intl"
import { localized } from "../../utils/localized"
import styles from "./blog-post.module.css"

function BlogPost(props) {
  const {
    _rawBody,
    authors,
    categories,
    title,
    mainImage,
    publishedAt,
    nextPagePath,
    previousPagePath,
    nextItem,
    previousItem,
  } = props
  const dateStr = format(new Date(publishedAt), "dd MMMM yyyy")
  return (
    <div className="container">
      <article className={styles.root}>
        <Container>
          <div>
            {mainImage && mainImage.asset && (
              <div className={styles.mainImage}>
                <img
                  src={imageUrlFor(buildImageObj(mainImage))
                    .width(900)
                    .height(450)
                    .auto("format")
                    .url()}
                  alt={mainImage.alt}
                />
              </div>
            )}
            <section className={styles.post_full_meta}>
              {dateStr}
              {categories &&
                categories.map(c => (
                  <span className="category-header" key={c.slug.current}>
                    <Link className="link" to={`/blog/${c.slug.current}`}>
                      {localized(c.title)}
                    </Link>
                  </span>
                ))}
              <h2 className={styles.title}>{title}</h2>
            </section>
            {authors && <AuthorList items={authors} title="Authors" />}
            <div className={styles.mainContent}>
              {_rawBody && <PortableText blocks={_rawBody} />}
            </div>
            <aside className={styles.metaContent}>
              <div className="flex justify-between">
                {previousPagePath ? (
                  <Link to={`/${previousPagePath}`} className="text-left">
                    <i className="fas fa-arrow-left icon icon--sm margin-right-xxs" />
                    {previousItem.node.title}
                  </Link>
                ) : (
                  <></>
                )}
                {nextPagePath ? (
                  <Link to={`/${nextPagePath}`} className="text-right">
                    {nextItem.node.title}
                    <i className="fas fa-arrow-right icon icon--sm margin-right-xxs" />
                  </Link>
                ) : (
                  <></>
                )}
              </div>
            </aside>
          </div>
        </Container>
      </article>
    </div>
  )
}

export default BlogPost
