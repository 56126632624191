import React from "react"
import { buildImageObj } from "./lib/helpers"
import { imageUrlFor } from "./lib/image-url"

import styles from "./author-list.module.css"

function AuthorList({ items, title }) {
  return (
    <div className="flex justify-center">
      {items.map(({ name, profilePhoto, jobTitle }) => {
        return (
          <div className="flex" key={name}>
            <div className={styles.avatar}>
              {profilePhoto.asset && (
                <img
                  src={imageUrlFor(buildImageObj(profilePhoto))
                    .width(100)
                    .height(100)
                    .fit("crop")
                    .auto("format")
                    .url()}
                  alt=""
                />
              )}
            </div>
            <div>
              <div>{name || <em>Missing name</em>}</div>
              <div className={styles.jobTitle}>{jobTitle}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AuthorList
