import React from "react"
import { graphql } from "gatsby"
// import GraphQLErrorList from "../components/graphql-error-list"
import BlogPost from "../components/blog/blog-post"
import SEO from "../components/seo"
import Layout from "../components/layout"
// import { toPlainText } from "../lib/helpers"

export const query = graphql`
  query BlogPostTemplateQuery($pageId: String!) {
    post: sanityPost(id: { eq: $pageId }) {
      id
      publishedAt
      categories {
        _id
        title {
          en
          vi
        }
        slug {
          current
        }
      }
      mainImage {
        ...SanityImageFields
      }
      title
      singleLang
      slug {
        current
      }
      excerpt
      _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        _key
        profilePhoto {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        name
        jobTitle
      }
    }
  }
`

const BlogPostTemplate = props => {
  const { data, pageContext } = props
  const post = data && data.post
  return (
    <Layout>
      <SEO
        title={post.title}
        description={post.excerpt}
        singleLang={post.singleLang}
        image={post.mainImage.asset.url}
        baseUrl={"blog/" + data.post.slug.current}
      />
      {/* {errors && <SEO title="GraphQL Error" />}
      {post && (
        <SEO
          title={post.title || "Untitled"}
          description={toPlainText(post._rawExcerpt)}
          image={post.mainImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )} */}
      <BlogPost
        {...post}
        previousPagePath={pageContext.previousPagePath}
        previousItem={pageContext.previousItem}
        nextPagePath={pageContext.nextPagePath}
        nextItem={pageContext.nextItem}
      />
    </Layout>
  )
}

export default BlogPostTemplate
